import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ui: {
    loading: false,
    updating: false,
    saving: false,
    deleting: false,
    loadingCategory: false,
  },
  stats: {},
  products: [],
  product: {},
  categories: [],
};

export const productsReducerSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    get_products: (state) => {
      state.ui.loading = true;
    },
    get_products_success: (state, { payload }) => {
      const { data, pagination } = payload;
      state.ui.loading = false;
      state.stats = {
        ...pagination,
      };
      state.products = data;
    },
    get_products_error: (state) => {
      state.ui.loading = false;
    },
    auto_create_products: (state) => {
      state.ui.loading = true;
    },
    auto_create_products_success: (state, { payload }) => {
      state.ui.loading = false;
      const { data } = payload;
      state?.products?.unshift(data[0]);
    },
    auto_create_products_error: (state) => {
      state.ui.loading = false;
    },
    search_products: (state) => {
      state.ui.loading = true;
    },
    search_products_success: (state, { payload }) => {
      state.ui.loading = false;
      const { data } = payload;
      state.products = data;
    },
    search_products_error: (state) => {
      state.ui.loading = false;
    },
    create_products: (state) => {
      state.ui.saving = true;
    },
    create_products_success: (state) => {
      state.ui.saving = false;
    },
    create_products_error: (state) => {
      state.ui.saving = false;
    },
    get_single_product: (state) => {
      state.ui.loading = true;
    },
    get_single_product_success: (state, { payload }) => {
      state.ui.loading = false;
      state.product = payload;
    },
    get_single_product_error: (state) => {
      state.ui.loading = false;
    },
    update_product: (state) => {
      state.ui.updating = true;
    },
    update_product_success: (state) => {
      state.ui.updating = false;
    },
    update_product_error: (state) => {
      state.ui.updating = false;
    },
    delete_product: (state) => {
      state.ui.deleting = true;
    },
    delete_product_success: (state) => {
      state.ui.deleting = false;
    },
    delete_product_error: (state) => {
      state.ui.deleting = false;
    },
    add_product_category: (state) => {
      state.ui.loadingCategory = true;
    },
    add_product_category_success: (state, { payload }) => {
      state.ui.loadingCategory = false;
      state?.categories?.unshift(payload[0]);
    },
    add_product_category_error: (state) => {
      state.ui.loadingCategory = false;
    },
    get_product_category: (state) => {
      state.ui.loadingCategory = true;
    },
    get_product_category_success: (state, { payload }) => {
      state.ui.loadingCategory = false;
      state.categories = payload;
    },
    get_product_category_error: (state) => {
      state.ui.loadingCategory = false;
    },
    bulk_upload_products: (state) => {
      state.ui.saving = true;
    },
    bulk_upload_products_success: (state, { payload }) => {
      state.ui.saving = false;
      console.log(payload);
    },
    bulk_upload_products_error: (state) => {
      state.ui.saving = false;
    },
  },
});

export const {
  get_products,
  get_products_success,
  get_products_error,
  auto_create_products,
  auto_create_products_success,
  auto_create_products_error,
  search_products,
  search_products_success,
  search_products_error,
  create_products,
  create_products_success,
  create_products_error,
  get_single_product,
  get_single_product_success,
  get_single_product_error,
  update_product,
  update_product_success,
  update_product_error,
  delete_product,
  delete_product_success,
  delete_product_error,
  add_product_category,
  add_product_category_success,
  add_product_category_error,
  get_product_category,
  get_product_category_success,
  get_product_category_error,
  bulk_upload_products,
  bulk_upload_products_error,
  bulk_upload_products_success,
} = productsReducerSlice.actions;

export default productsReducerSlice.reducer;
