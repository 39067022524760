import axios from "axios";
import { getWithExpiry } from "utils/store";

let api = axios.create({
  baseURL: process.env.REACT_APP_PROD_URL,
  headers: {
    Accept: "application/json",
  },
});

api.interceptors.request.use(
  async (config) => {
    const token = await getWithExpiry("l-access-token");
    if (token) {
      if (config.headers) {
        config.headers.Authorization = `${token}`;
      } else {
        config.headers = { Authorization: `${token}` };
      }
    }
    return config;
  },
  (error) => {
    throw error;
  },
);

export default api;
